.content.details {
    padding: 30px;
}

.small-header.details {
    float: left;
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
}

.small-header.details ul {
    float: left;
}

.small-header.details li {
    float: left;
}

.small-header.details li div {
    font-size: 14px;
}

.small-header.details li :after {
    top: 15px;
    width: 1px;
}

.small-header.details li:last-child :after {
    width: 0;
}

.small-header.details li:first-child a {
    padding-left: 7px;
}

.details-icons {
    float: right;
}

.details-icons button {
    border: none;
    background-color: white;
}

.details-icons .print-icon,
.details-icons .send-icon {
    height: 23px;
    padding: 10px;
}

hr {
    margin: 70px 7px 10px;
}

caption {
    font-size: 17px;
    padding: 15px 8px;
    text-align: left;
    min-width: 250px;
}

caption div {
    vertical-align: middle;
    display: inline-block;
    padding-right: 30px;
    margin-top: 4px;
}

.edit-button {
    border: 1px solid white;
    background-color: white;
}

.edit-button:active {
    color: black;
}

.edit-button img.cancel-icon, .edit-button img.update-icon, .edit-button img.edit-icon {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    padding-right: 3px;
    padding-top: 2px;
}

.edit-button .cancel-text,
.edit-button .update-text,
.edit-button .edit-text {
    vertical-align: middle;
    font-size: 15px;
}

.edit-button:last-child {
    margin-left: 8px;
    margin-right: 8px;
}

.edit-button:active {
    border: 1px solid white;
}

.edit-button {
    border-color: white;
}

.edit-button.override {
    position: absolute;
}

.description-table {
    width: 50%;
    display: inline-table;
    empty-cells: show;
}

.description-table th {
    width: 100%;
}

.description-table td {
    padding: 7px;
    height: 30px;
}

.description-table td:first-child {
    width: 35%;
}

.description-table td:nth-child(2) {
    width: 65%;
    max-width: 50vw;
    word-wrap: break-word;
}

.description-table td:nth-child(2):not(input) {
    margin-left: 3px;
}

.rp-info-table {
    width: 100%;
}

.rp-info-table td:first-child {
    width: 17.5%;
}

.rp-info-table td:nth-child(2) {
    width: 32.5%;
}

.rp-info-table td:nth-child(3) {
    width: 17.5%;
}

.rp-info-table td:nth-child(4) {
    width: 32.5%;
}

.transaction-notes {
    padding: 7px;
}

.transaction-notes-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 30px;
}

.transaction-notes-table td {
    line-height: 1.8;
    vertical-align: top;
    padding: 20px;
}

.transaction-notes-table td:first-child,
.transaction-notes-table td:nth-child(2) {
    width: 25%;
    padding-left: 7px;
}

.transaction-notes-table td:last-child {
    padding: 10px 20px;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    width: 50%;
    border: solid 1px rgba(151, 151, 151, 0.38);
    box-sizing: border-box;
}

.transaction-notes-table p {
    line-height: 1.5;
    padding: 10px 0;
    word-break: break-word;
}

.plc-table {
    width: 50%;
    empty-cells: show;
    display: inline-table;
    line-height: 28px;
    font-weight: 300;
}

.plc-table caption {
    padding: 5px 5px;
}

.plc-table td:first-child {
    width: 43%;
}

.authorization {
    position: absolute;
    right: 55px;
    top: 600px;
}

.authorization .link {
    text-decoration: underline;
    float: right;
}

.authorization .link:hover {
    cursor: pointer;
}

.authorization .details-tables {
    display: inline-table;
    margin-top: 37px;
    margin-right: -154px;
    padding: 32px;
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    background-color: #f9f9f9;
    line-height: 30px;
}

.authorization .details-tables.hide {
    display: none;
}

.authorization .details-tables .inline {
    display: inline-table;
    width: 300px;
    border-left: 1px solid black;
}

.authorization .details-tables .inline tr td {
    padding-left: 20px;
    padding-right: 0;
}

.authorization .details-tables .inline:first-child {
    border: none;
}

.recurring-details .authorization {
    top: 800px;
}

.example.appear {
    transform: translate(100%);
}

.example.appear-done {
    transform: translate(0%);
    transition: transform 500ms ease-in-out;
}

.change-information {
    font-size: 15px;
    font-weight: 600;
    height: 21px;
    width: 100%;
    color: #333333;
    background-color: white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    border-left: 1px solid white;
    margin-left: -1px;
    border-right: none;
    min-width: 100px;
    max-width: 300px;
    text-overflow: ellipsis;
    padding: 2px 4px;
}

.change-information.short {
    width: 50px;
}

.change-information.datetimepicker {
    height: 23px;
}

.change-information:enabled {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    border: solid 1px #c9c9c9;
}

.change-information.error {
    border: solid 1px #cc0000;
}

.change-information.plc-change {
    height: 26px;
}

.table-submit {
    display: inline-block;
}

.hide {
    display: none;
}

.override-information {
    text-decoration: underline;
}

.override-information:hover {
    cursor: pointer;
}

.for-print-only {
    display: none;
}

.back-button {
    border: none;
    background-color: #f9f9f9;
}

.react-datetime-picker {
    font-weight: 600;
    border: solid 1px #c9c9c9;
}

.react-datetime-picker .react-datetime-picker__wrapper {
    border: none;
}

.approve-deny-buttons {
    float: right;
}

.transaction-details .approve-deny-buttons {
    width: 70%;
    justify-content: flex-end;
    display: flex;
}

@media only screen and (max-width: 600px) {
    div {
        max-width: 100%;
    }

    .content.details {
        padding: 10px;
    }

    .small-header.details li div {
        padding: 0 8px;
    }

    .small-header.details li :after {
        width: 0;
    }

    .description-table {
        width: 100%;
    }

    .description-table tbody {
        display: table-row-group;
    }

    .description-table td:first-child {
        width: 50vw;
    }

    .details-icons {
        display: none;
    }

    .transaction-notes-table tbody {
        display: grid;
    }

    .transaction-notes-table tr {
        margin: 15px 0;
        max-width: 90vw;
    }

    .transaction-notes-table td {
        padding: 5px;
    }

    .transaction-notes-table td:first-child {
        display: block;
        width: 100%;
    }

    .transaction-notes-table td:nth-child(2) {
        display: block;
        width: 100%;
    }

    .transaction-notes-table td:last-child {
        display: block;
        width: 100%;
        box-sizing: border-box;
    }
}

@media only screen and (max-width: 600px) {
    caption div {
        padding-right: 10px;
    }

    .approve-deny-buttons {
        float: none;
        display: block !important;
        width: 100% !important;
    }

    .approve-deny-buttons .right {
        float: left !important;
    }

    .approve-deny-buttons .proview-button {
        margin: 10px 3vw;
    }
}

@media print {
    .content.details {
        padding: 0;
    }

    #table-header,
    .edit-button,
    .small-header.details,
    .transaction-notes,
    .authorization {
        display: none;
    }

    #logo {
        display: block;
    }

    .for-print-only {
        width: 100%;
        display: block;
    }

    .for-print-only tbody {
        display: table;
        width: 100%;
    }

    .for-print-only tbody tr td {
        width: 50%;
    }

    .signature:after {
        content: "";
        display: block;
        /* margin: 0 auto; */
        margin-left: 161px;
        width: 60%;
        padding-top: 11px;
        border-bottom: 1px solid black;
    }

    .signature-date:after {
        content: "";
        display: block;
        margin-left: 50px;
        width: 60%;
        padding-top: 11px;
        border-bottom: 1px solid black;
    }
}

.captureMessage .title {
    float: left;
}

.captureMessage .messages {
    float: left;
    margin-left: 10px;
}
