* {
    font-family: "Open Sans", sans-serif;
    margin: 0;
    padding: 0;
}

body {
    background-color: #f9f9f9;
}

table {
    width: auto;
}

textarea,
input {
    font-weight: 300;
}

.hidden {
    display: none !important;
}

.proview-select {
    font-size: 12px;
    color: #333333;
    width: 100%;
}

.proview-select .select__control {
    border-radius: 2px;
}

.proview-select .select__control:hover {
    border-color: #28AC40;
}

.proview-select .select__control .select__value-container--has-value {
    padding: 4px;
}

.proview-select .select__control.select__control--menu-is-open {
    box-shadow: 0 0 0 1px #28AC40 !important;
    border-color: #28AC40;
}

.proview-select .select__control.select__control--is-focused {
    box-shadow: 0 0 0 1px #28AC40 !important;
    border-color: #28AC40;
}

.proview-select .select__menu .select__menu-list .select__option:hover, .proview-select .select__menu .select__menu-list .select__option--is-focused {
    background-color: #9ede73;
}

.proview-select .select__menu .select__menu-list .select__option--is-selected {
    background-color: #28AC40;
}

.proview-select .select__indicators {
    /* Replace dropdown icon */
}

.proview-select .select__indicators .select__dropdown-indicator {
    display: inline-block;
    position: absolute;
    padding: 19px;
    right: 0;
    top: 0;
    background: url(../icons/caret-down.svg) no-repeat center;
}

.proview-select .select__indicators .select__dropdown-indicator svg {
    display: none;
}

.proview-select .select__indicators .select__indicator-separator {
    display: none;
}

@media screen and (max-width: 600px) {
    .proview-select .select__control {
        width: 100%;
        height: 48px;
    }
}

/* Customize search inputs */
.proview-input {
    height: 38px;
    width: 100%;
    padding: 0 8px;
    font-size: 12px;
    border-radius: 2px;
    border: 1px solid #c9c9c9;
    box-sizing: border-box;
}

.proview-input.long {
    width: 182px;
}

.proview-input::placeholder {
    color: black;
}

.proview-input:hover {
    border: 1px solid #28AC40;
}

.proview-input.error {
    border: solid 1px #cc0000;
}

.proview-input.filter:valid {
    background-color: #28AC40;
    color: white;
}

.proview-input.details {
    width: 100%;
    padding: 8px 0;
    padding-left: 10px;
    height: 99px;
    resize: none;
}

.proview-input.login {
    margin: 12px 0;
}

.proview-button {
    width: 162px;
    height: 50px;
    border: solid 1px #28AC40;
    color: #ffffff;
    background-color: #28AC40;
    border-radius: 2px;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
}

.proview-button.right {
    float: right;
    margin-left: 20px;
}

.proview-button.left {
    float: left;
}

.proview-button:disabled {
    background-color: white;
    color: #28AC40;
    cursor: default;
}

.proview-button.regiser, .proview-button.cancel {
    background-color: white;
    color: #28AC40;
    cursor: pointer;
}

.proview-button.margins {
    margin: 25px 20px;
}

.proview-button.no-margin-left {
    margin-left: 0;
}

.proview-button.long {
    width: 182px;
}

.proview-button.report {
    height: 38px;
}

.proview-button.deny {
    height: 48px;
}

.proview-button.deny .deny-icon {
    padding: 0 12px;
}

.proview-button.deny .dropdown-content {
    left: -20px;
    top: 49px;
    z-index: 1;
}

.proview-button.deny .dropbtn {
    line-height: 50px;
    padding-left: 10px;
    text-decoration: none;
    /* no underline */
    color: #28AC40;
}

.proview-button.deny .dropbtn:focus, .proview-button.deny .dropbtn:active {
    color: #28AC40;
}

.proview-button.reject {
    line-height: 50px;
    padding-left: 10px;
    background-color: #fff;
    color: #28AC40;
    border: solid 0px;
    height: 48px;
}

.proview-button.login {
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
}

.proview-button.register:enabled {
    background-color: white;
    color: #9ede73;
    cursor: pointer;
}

/* Tooltip css */
.img-question {
    margin-left: 3px;
}

.tab {
    margin-left: 20px;
}

#password-tip {
    opacity: 1;
    color: #222;
    background: #f9f9f9;
    box-shadow: 1px 1px 1px 1px #c9c9c9;
}

#password-tip::after {
    border-right-color: #c9c9c9 !important;
}

/* QuickBooks Sync */
.title-qb {
    padding: 0px 8px 20px 8px !important;
    text-align: center !important;
    font-weight: bold !important;
}

.scrollbar-qb {
    width: 650px;
    height: 230px;
    overflow-y: auto;
}

.scrollbar-qb::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.scrollbar-qb::-webkit-scrollbar-thumb {
    background-color: #d5d2d2;
    border-radius: 10px;
}

.font-table {
    font-size: 14px;
}

.modal-qb {
    top: -30px !important;
    width: 650px !important;
    background-color: #fff !important;
    height: 375px !important;
}

.modal-qb #page {
    width: 92%;
}

.qb-info-table {
    width: 100%;
}

.qb-info-table td:first-child {
    width: 15%;
}

.qb-info-table td:nth-child(2) {
    width: 20%;
}

.qb-info-table td:nth-child(3) {
    width: 25%;
}

.qb-info-table td:nth-child(4) {
    width: 30%;
}

/* Right align desired columns */
.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.hoverable {
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    #note button {
        width: 100%;
        max-width: 100%;
        float: none;
    }

    .hide-mobile {
        display: none;
    }

    button {
        height: 48px;
    }

    .proview-input,
    .proview-select {
        font-size: 16px;
    }

    .proview-button {
        width: 40vw;
        max-width: 162px;
    }

    .proview-button.right {
        margin: 0px 0;
    }

    .proview-button.mobile {
        width: 100%;
    }

    .proview-button.margins {
        max-width: 150px;
        margin: 0px 3vw;
    }

    .proview-button.deny .dropdown-content {
        width: 40vw;
        left: 0;
        right: -20px;
    }

    .proview-button.deny .dropdown-content div {
        height: 40px;
        line-height: default;
    }
}

.generate-report,
.transaction-sum,
.transaction-sum-sync,
.transaction-man,
.recurring-pay {
    /* End of calendar tapers off, also correct coloring */
    /* If value filled with nothing, keep background the same */
}

.generate-report .CalendarDay,
.transaction-sum .CalendarDay,
.transaction-sum-sync .CalendarDay,
.transaction-man .CalendarDay,
.recurring-pay .CalendarDay {
    border: 0;
}

.generate-report .DateRangePickerInput_arrow,
.transaction-sum .DateRangePickerInput_arrow,
.transaction-sum-sync .DateRangePickerInput_arrow,
.transaction-man .DateRangePickerInput_arrow,
.recurring-pay .DateRangePickerInput_arrow {
    display: none;
}

.generate-report .CalendarDay__default:hover,
.transaction-sum .CalendarDay__default:hover,
.transaction-sum-sync .CalendarDay__default:hover,
.transaction-man .CalendarDay__default:hover,
.recurring-pay .CalendarDay__default:hover {
    border: 0;
}

.generate-report .DateRangePicker,
.transaction-sum .DateRangePicker,
.transaction-sum-sync .DateRangePicker,
.transaction-man .DateRangePicker,
.recurring-pay .DateRangePicker {
    height: 38px;
}

.generate-report .DateInput,
.transaction-sum .DateInput,
.transaction-sum-sync .DateInput,
.transaction-man .DateInput,
.recurring-pay .DateInput {
    width: 68px;
}

.generate-report .DateInput_input,
.transaction-sum .DateInput_input,
.transaction-sum-sync .DateInput_input,
.transaction-man .DateInput_input,
.recurring-pay .DateInput_input {
    font-size: 12px;
    height: 34px;
    width: 68px;
    padding: 0 11px;
}

.generate-report .DateInput_input::placeholder,
.transaction-sum .DateInput_input::placeholder,
.transaction-sum-sync .DateInput_input::placeholder,
.transaction-man .DateInput_input::placeholder,
.recurring-pay .DateInput_input::placeholder {
    color: black;
}

.generate-report .DateRangePickerInput__showClearDates,
.transaction-sum .DateRangePickerInput__showClearDates,
.transaction-sum-sync .DateRangePickerInput__showClearDates,
.transaction-man .DateRangePickerInput__showClearDates,
.recurring-pay .DateRangePickerInput__showClearDates {
    padding-right: 22px;
}

.generate-report .CalendarDay__selected:hover,
.transaction-sum .CalendarDay__selected:hover,
.transaction-sum-sync .CalendarDay__selected:hover,
.transaction-man .CalendarDay__selected:hover,
.recurring-pay .CalendarDay__selected:hover {
    background: #28AC40;
}

.generate-report .CalendarDay__selected_span,
.transaction-sum .CalendarDay__selected_span,
.transaction-sum-sync .CalendarDay__selected_span,
.transaction-man .CalendarDay__selected_span,
.recurring-pay .CalendarDay__selected_span {
    background: #28AC40;
    color: white;
    border: 0px solid #28AC40;
}

.generate-report .CalendarDay__selected_start,
.transaction-sum .CalendarDay__selected_start,
.transaction-sum-sync .CalendarDay__selected_start,
.transaction-man .CalendarDay__selected_start,
.recurring-pay .CalendarDay__selected_start {
    background: #28AC40;
    border-top-left-radius: 12px;
    border-color: #28AC40;
    border-bottom-left-radius: 12px;
    color: white;
}

.generate-report .DateRangePickerInput_clearDates,
.transaction-sum .DateRangePickerInput_clearDates,
.transaction-sum-sync .DateRangePickerInput_clearDates,
.transaction-man .DateRangePickerInput_clearDates,
.recurring-pay .DateRangePickerInput_clearDates {
    margin: 2px -4px 0 5px;
}

.generate-report .CalendarDay__selected_end,
.transaction-sum .CalendarDay__selected_end,
.transaction-sum-sync .CalendarDay__selected_end,
.transaction-man .CalendarDay__selected_end,
.recurring-pay .CalendarDay__selected_end {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    background: #28AC40;
    color: white;
    border-color: #28AC40;
}

.generate-report .CalendarDay__hovered_span:hover,
.generate-report .CalendarDay__hovered_span,
.transaction-sum .CalendarDay__hovered_span:hover,
.transaction-sum .CalendarDay__hovered_span,
.transaction-sum-sync .CalendarDay__hovered_span:hover,
.transaction-sum-sync .CalendarDay__hovered_span,
.transaction-man .CalendarDay__hovered_span:hover,
.transaction-man .CalendarDay__hovered_span,
.recurring-pay .CalendarDay__hovered_span:hover,
.recurring-pay .CalendarDay__hovered_span {
    background: #28AC40;
    color: white;
    border-color: #28AC40;
}

.generate-report .DateInput_input__focused,
.transaction-sum .DateInput_input__focused,
.transaction-sum-sync .DateInput_input__focused,
.transaction-man .DateInput_input__focused,
.recurring-pay .DateInput_input__focused {
    border-bottom-color: #28AC40;
}

.generate-report .DateRangePickerInput__withBorder,
.transaction-sum .DateRangePickerInput__withBorder,
.transaction-sum-sync .DateRangePickerInput__withBorder,
.transaction-man .DateRangePickerInput__withBorder,
.recurring-pay .DateRangePickerInput__withBorder {
    border-color: #c9c9c9;
}

.generate-report .DateRangePickerInput__withBorder:hover,
.transaction-sum .DateRangePickerInput__withBorder:hover,
.transaction-sum-sync .DateRangePickerInput__withBorder:hover,
.transaction-man .DateRangePickerInput__withBorder:hover,
.recurring-pay .DateRangePickerInput__withBorder:hover {
    border-color: #28AC40;
}

.generate-report .DateInput_input__focused,
.transaction-sum .DateInput_input__focused,
.transaction-sum-sync .DateInput_input__focused,
.transaction-man .DateInput_input__focused,
.recurring-pay .DateInput_input__focused {
    border-bottom-color: #28AC40;
}

.generate-report .select__multi-value,
.transaction-sum .select__multi-value,
.transaction-sum-sync .select__multi-value,
.transaction-man .select__multi-value,
.recurring-pay .select__multi-value {
    width: 72px;
}

.generate-report #start-filter-page[value],
.generate-report #end-filter-page[value],
.transaction-sum #start-filter-page[value],
.transaction-sum #end-filter-page[value],
.transaction-sum-sync #start-filter-page[value],
.transaction-sum-sync #end-filter-page[value],
.transaction-man #start-filter-page[value],
.transaction-man #end-filter-page[value],
.recurring-pay #start-filter-page[value],
.recurring-pay #end-filter-page[value] {
    background-color: #28AC40;
    color: white;
}

.generate-report #start-filter-page[value=""],
.generate-report #end-filter-page[value=""],
.transaction-sum #start-filter-page[value=""],
.transaction-sum #end-filter-page[value=""],
.transaction-sum-sync #start-filter-page[value=""],
.transaction-sum-sync #end-filter-page[value=""],
.transaction-man #start-filter-page[value=""],
.transaction-man #end-filter-page[value=""],
.recurring-pay #start-filter-page[value=""],
.recurring-pay #end-filter-page[value=""] {
    background-color: white;
    color: black;
}

.generate-report .DateInput {
    width: 90px;
}

.generate-report .payment-auth .DateInput {
    width: 80px;
}

.generate-report .DateInput_input {
    width: 100%;
}

.generate-report .SingleDatePicker {
    width: 150px;
}

.generate-report .SingleDatePicker .SingleDatePickerInput__withBorder {
    border-color: #c9c9c9;
}

.generate-report .SingleDatePicker .SingleDatePickerInput__withBorder:hover {
    border-color: #28AC40;
}

.generate-report .SingleDatePicker .DateInput {
    width: 120px;
}

.generate-report .SingleDatePicker .DateInput_input {
    width: 98px;
}

.transaction-man .DateInput {
    width: 74px;
}

.transaction-man .DateInput_input {
    width: 74px;
}
.rs-picker-daterange {
  .rs-input-group-focus input {
    border: 1px solid #28ac40 !important;
    border-color: #28ac40 !important;
  }
  .rs-input-group {
    border-color: #c9c9c9 !important;
    border-radius: 0% !important;
    outline: none !important;
  }
  .rs-input-group {
    border: none;
  }

  .rs-input-group input:hover {
    border-color: #28ac40 !important;
  }
  .rs-input-group input:focus-visible {
    border-color: #28ac40 !important;
  }
  .rs-input-group input {
    font-size: 12px !important;
    border: 1px solid #c9c9c9 !important;
    height: 36px;
    border-radius: 0% !important;
  }
  .rs-input-group span {
    position: absolute !important;
  }
  /* .rs-calendar-table-cell-selected {
    .rs-calendar-table-cell-content {
      background-color: #28ac40 !important;
      font-size: 20px !important;
    }
  } */
}
.rs-calendar {
  .rs-calendar-table-cell:hover {
    .rs-calendar-table-cell-content {
      color: #28ac40 !important;
      background-color: rgb(#28ac40 / 50%);
    }
  }
  .rs-calendar-table-cell-selected-start,
  .rs-calendar-table-cell-selected-end {
    .rs-calendar-table-cell-content {
      background-color: #28ac40;
    }
  }
  .rs-calendar-table-cell-in-range::before {
    background-color: #28ac40;
    opacity: 0.3;
  }
}
.rs-picker-toolbar {
  .rs-btn-sm {
    background-color: #28ac40;
  }
  .rs-btn-sm:hover {
    background-color: #28ac40;
  }
  .rs-btn-sm:visited {
    background-color: #28ac40;
  }
  .rs-btn-sm:active {
    background-color: #28ac40;
  }
  .rs-btn-sm:focus {
    background-color: #28ac40;
  }
  .rs-btn-disabled {
    background-color: #28ac40 !important;
  }
}
