.main-background {
    background-color: #f9f9f9;
}

.main-component {
    margin: 0 auto;
    width: 1200px;
    font-weight: 300;
    color: #333333;
    font-size: 15px;
}

#trans-sum-icon {
    height: 30px;
    padding-top: 3px;
    padding-right: 10px;
}

#trans-details-icon {
    height: 21px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 25px;
}

#trans-manifest-icon {
    height: 54px;
    margin-top: -9px;
    padding-right: 5px;
    margin-left: -19px;
}

#reports-and-statements-icon {
    height: 52px;
    margin-top: -7px;
    padding-right: 7px;
    margin-left: -19px;
}

#payment-exclusion-icon {
    height: 53px;
    margin-top: -9px;
    padding-right: 2px;
    margin-left: -19px;
}

#table-header {
    height: 100px;
    clear: both;
}

#details-label {
    margin: 30px 0;
    padding: 5px;
    font-size: 30px;
    line-height: 1.1;
    color: #333333;
    float: left;
}

#details-label-not-found {
    margin: 30px 0;
    padding: 5px;
    font-size: 30px;
    line-height: 1.1;
    color: #333333;
    float: left;
    width: 100%;
}

#details-label-name {
    float: right;
}

@media only screen and (max-width: 600px) {
    .main-background,
    .main-component {
        width: 100%;
    }

    #trans-sum-icon {
        height: 6vw;
        margin: auto 0;
        padding: 0 10px;
    }

    .back-button {
        height: 100%;
    }

    #trans-details-icon {
        height: 5vw;
        vertical-align: middle;
        margin: auto 0;
        padding: 0 10px;
    }

    #table-header {
        padding: 10px 0;
        height: 100%;
        display: inline-block;
        width: 100%;
    }

    #details-label {
        margin: 0;
        padding: 20px 0;
        font-size: 6.5vw;
        height: 10vw;
        display: flex;
        width: 100%;
    }

    #details-label-name {
        float: none;
        margin: auto 0;
    }

    #logo {
        padding: 14px;
    }
}
