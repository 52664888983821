.download-link {
    position: absolute;
    right: 44px;
    top: 34px;
    text-decoration: underline;
    border: none;
    font-size: 15px;
    background-color: white;
}

.download-link.history {
    top: 20px;
    right: 31px;
}

.download-link:hover {
    cursor: pointer;
}

.download-link:active {
    color: black;
}
