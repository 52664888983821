.footer {
    padding-top: 24px;
    padding-bottom: 24px;
    margin: 0 auto;
    width: 1200px;
    height: 100px;
    font-weight: 300;
    color: #333333;
    font-size: 15px;
}

@media only screen and (max-width: 600px) {
    .footer {
        display: none;
    }
}

@media print {
    .footer {
        display: none;
    }
}
