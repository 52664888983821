#sort {
    height: 58px;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
}

.sorting:hover {
    cursor: pointer;
}

.sorting:hover label:hover {
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    #sort {
        display: none;
    }
}
