/* Alternate grey on each row, also hover effects on whole row */
.row {
    height: 45px;
}

.row:hover {
    background-color: rgba(51, 102, 153, 0.07);
    cursor: pointer;
}

.row:hover button {
    background: transparent;
}

.row:nth-child(odd) {
    background: #f9f9f9;
}

.row:nth-child(odd) button {
    background: transparent;
}

.row:nth-child(odd):hover {
    background-color: rgba(51, 102, 153, 0.07);
    cursor: pointer;
}

.row:nth-child(odd):hover button {
    background: transparent;
}

.gr_row {
    height: 45px;
}

.gr_row:hover {
    background-color: rgba(51, 102, 153, 0.07);
    cursor: pointer;
}

.gr_row:hover button {
    background: transparent;
}

.gr_row:nth-child(even) {
    background: #f9f9f9;
}

.gr_row:nth-child(even) button {
    background: transparent;
}

.gr_row:nth-child(even):hover {
    background-color: rgba(51, 102, 153, 0.07);
    cursor: pointer;
}

.gr_row:nth-child(even):hover button {
    background: transparent;
}

td.INCOMPLETE, td.READY, td.PENDING, td.DENIED, td.CANCELLED, .CASHPENDING {
    padding-right: 45px;
}

td.APPROVED {
    color: #cc0000;
}

td.PAID {
    color: #28AC40;
}

.ui.checkbox {
    height: 18px;
    width: 18px;
}

/* Specify width of buttons*/
#buttons {
    width: 110px;
}

/* Make buttons on same row */
.row-button {
    display: inline-block;
    padding: 5px 6px;
    /* Style descriptions on button hover, default hidden */
}

.row-button .row-description {
    position: absolute;
    margin-top: 40px;
    margin-left: 1px;
    display: none;
    font-size: 10px;
    color: #336699;
    width: 57px;
    height: 19px;
    box-shadow: 0 1px 11px 0 rgba(18, 62, 105, 0.27);
    background-color: #e0ecf7;
    text-align: center;
}

.row-button:hover {
    cursor: pointer;
    /* On hover, show dropdowns and change icon opacity */
}

.row-button:hover .row-description {
    display: inline-block;
}

.row-button:hover .row-icon g path,
.row-button:hover .row-icon g g {
    opacity: 1;
    fill-rule: evenodd;
}

.transaction-id-button {
    font-size: 14px;
    border: none;
    background-color: transparent;
    padding: 5px 0;
}

.transaction-id-button:hover {
    cursor: pointer;
}

.report-form.resend {
    padding-bottom: 25px;
    display: inline-block;
    width: 100%;
}

.report-form.resend .select__menu .select__menu-list .select__option:hover {
    background-color: #9ede73;
}

.report-form.resend .select__menu .select__menu-list .select__option--is-selected {
    background-color: white;
}

.row.history.totals {
    border-top: 1px solid #000;
    height: 60px;
    background-color: #f9f9f9;
}

@media only screen and (max-width: 600px) {
    .mobile-left {
        float: left;
        width: 50%;
    }

    .row {
        background: #fafafa;
        min-height: 100%;
        height: 100%;
        line-height: 27px;
        padding: 30px 10px;
    }

    .row:nth-child(odd) {
        background: white;
    }

    .payment-row,
    .transaction-id-row {
        font-weight: 600;
    }

    .transaction-id-row::before {
        content: "Ref: ";
        font-weight: 300;
    }

    .text-right {
        padding-right: 40px;
    }
}
