#note {
    padding: 25px 40px;
    background-color: #f9f9f9;
    font-size: 14px;
    font-weight: 300;
    line-height: 2.36;
}

#note table {
    width: 100%;
}

#note td {
    padding: 0px 20px;
}

#note td:first-child {
    padding: 0;
    width: 15%;
}

#note td:nth-child(2) {
    width: 20%;
}

#note td:nth-child(3) {
    width: 20%;
}

#note td:nth-child(4) {
    padding-right: 0;
    width: 40%;
}

#note button {
    float: right;
}

#note table {
    margin: 10px 0;
}

#note .notes-bottom {
    line-height: normal;
}

@media only screen and (max-width: 600px) {
    #note {
        margin-top: 40px;
        padding: 25px 8px;
    }

    #note td {
        display: inline-block;
        padding: 0;
    }

    #note td:first-child {
        width: 100%;
    }

    #note td:first-child:before {
        margin-right: 20px;
        content: "Transaction ID";
    }

    #note td:nth-child(2) {
        width: 100%;
    }

    #note td:nth-child(2):before {
        content: "Issue Type";
        float: left;
    }

    #note td:nth-child(3) {
        width: 100%;
    }

    #note td:nth-child(3):before {
        content: "Priority";
    }

    #note td:nth-child(4) {
        width: 100%;
    }

    #note td:nth-child(4):before {
        content: "Email (if response desired)";
    }
}
