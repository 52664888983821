#logo {
    float: left;
    padding: 14px 0;
    margin-right: 130px;
}

#top-logo {
    height: 100px;
    width: 179px;
    margin-top: -40px;
}

#caret {
    padding: 0px 0px 1px 2px;
}

#header-background-color {
    background-color: white;
}

.header {
    margin: 0 auto;
    width: 1200px;
    height: 101px;
    background-color: white;
}

.small-header {
    height: 38px;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.main-header {
    height: 90px;
}

.main-header li {
    float: left;
}

.main-header li a {
    display: block;
    color: #333333;
    text-align: center;
    margin: 14px 0 0 35px;
    font-size: 15px;
    line-height: 2.2;
    letter-spacing: 0.4px;
    text-decoration: none;
    border-bottom: 2px solid white;
}

.main-header li a.active {
    color: #28AC40;
    font-weight: bold;
    border-bottom: 2px solid #28AC40;
}

.main-header li a:hover:not(.active) {
    color: #9ede73;
    font-weight: bold;
}

.main-header.finance-header li a {
    margin-left: 12px;
    line-height: 2.4;
    font-size: 14px;
}

.small-header {
    width: 100%;
}

.small-header ul {
    float: right;
}

.small-header li {
    float: left;
}

.small-header li a,
.small-header li div {
    cursor: pointer;
    display: block;
    color: #333333;
    text-align: center;
    font-weight: 300;
    font-size: 10px;
    line-height: 3.3;
    letter-spacing: 0.3px;
    text-decoration: none;
    position: relative;
    padding: 0px 15px;
}

.small-header li :after {
    content: "";
    height: 40%;
    width: 1px;
    font-weight: 300;
    background-color: #333333;
    position: absolute;
    right: 0;
    top: 10px;
}

.small-header li:last-child :after {
    width: 0;
}

.dropdown {
    position: relative;
}

.dropdown:focus .dropdown-content, .dropdown:hover .dropdown-content {
    display: block;
}

.dropdown .dropdown-content {
    margin: 0 0 0 20px;
    display: none;
    position: absolute;
    background-color: white;
    left: 0;
    right: 0;
    border-radius: 2px;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
    z-index: 3;
}

.dropdown .dropdown-content.show {
    display: block;
}

.dropdown .dropdown-content.none {
    display: none;
}

.dropdown .dropdown-content div,
.dropdown .dropdown-content a {
    color: black;
    margin: 6px 0;
    height: 100%;
    line-height: 34px;
    padding-left: 21px;
    padding-top: 6px;
    padding-bottom: 0;
    text-decoration: none;
    display: block;
    font-size: 12px;
    text-align: left;
}

.dropdown .dropdown-content div:hover,
.dropdown .dropdown-content a:hover {
    background-color: #9ede73;
    color: white !important;
}

.dropdown .dropdown-content button {
    width: 100%;
    margin: 0;
    height: 48px;
    padding-left: 21px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 34px;
    border: none;
    text-decoration: none;
    display: block;
    font-size: 12px;
    text-align: left;
}

.dropdown .dropdown-content button:hover {
    background-color: #9ede73;
    color: white;
    cursor: pointer;
}

.dropdown-content-logout {
    margin: 0 !important;
    padding: 0 !important;
    width: 150px;
}

.finance-header .dropdown-content {
    margin: 0 0 0 15px !important;
}

.finance-header .dropdown-content a {
    font-size: 12px !important;
    margin: 10px 0 !important;
}

.menu .dropdown .dropdown-content {
    margin: 0 0px 0 32px;
}

.menu-logout {
    padding-right: 35px;
}

.input-validate-password {
    font-size: 13px !important;
    height: 25px;
}

@media only screen and (max-width: 600px) {
    .menu .dropdown .dropdown-content a {
        display: none;
    }

    .main-header li a,
    .small-header li a.hide-mobile {
        display: none;
    }

    .main-header {
        height: 75px;
    }

    .small-header.top {
        height: 0;
        float: right;
        width: 124px;
    }

    .small-header.top li a {
        padding: 8px 14px;
        font-size: 18px;
        text-transform: lowercase;
    }

    .small-header.top li a:first-letter {
        text-transform: uppercase;
    }

    .small-header.top ul {
        width: 70%;
        margin: auto;
    }

    .header {
        height: 100%;
        width: 100%;
    }

    #top-logo {
        height: 41px;
        width: 50vw;
    }

    #logout-button {
        font-size: 22px;
        height: 75px;
        margin: auto;
    }
}

@media print {
    .header {
        display: none;
    }
}
