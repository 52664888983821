#LoginLogo {
    padding: 40px 0;
    height: 80px;
    width: 500px;
    max-width: 92vw;
}

.center {
    background-color: #f9f9f9;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-footer {
    background-color: #f9f9f9;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.card {
    background-color: #fafafa;
    padding: 0.8rem;
}

.card > form {
    display: flex;
    flex-direction: column;
}

.card h1 {
    text-align: center;
    margin-top: 5px 0;
}

.input-error {
    color: #cc0000;
}

.input-error.hidden {
    display: none;
}

.input-error.change {
    padding-top: 10px;
}
