#page {
  padding: 13px 5px;
  margin-top: 25px;
  height: 40px;
  width: 95%;
  border-top: 2px solid #c9c9c9;
  position: absolute;
  bottom: 0; }

.bottom {
  line-height: 40px; }
  .bottom.display {
    float: left;
    font-size: 14px;
    min-width: 200px;
    width: auto;
    white-space: nowrap; }
  .bottom.nav {
    text-align: center;
    margin: auto;
    width: 500px; }
    .bottom.nav svg {
      padding: 4px 10px; }
    .bottom.nav svg:hover {
      cursor: pointer; }
  .bottom.page-text {
    float: right;
    margin-right: 10px; }

.page-button {
  border: none;
  background-color: white;
  margin: 8px;
  height: 30px; }

#set-page {
  width: 166px;
  float: right;
  background-color: white;
  text-align: center; }
  #set-page .select__menu .select__menu-list .select__option:hover {
    background-color: #9ede73; }
  #set-page .select__menu .select__menu-list .select__option--is-selected {
    background-color: #28AC40; }

@media only screen and (max-width: 600px) {
  #page {
    display: none; } }
