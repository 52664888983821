.generate-report {
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 30px;
}

.generate-report input,
.generate-report label {
    display: block;
}

.generate-report label {
    margin-bottom: 5px;
}

.generate-report form {
    width: 100%;
}

.generate-report .plc-form {
    position: relative;
    width: 190px;
}

.generate-report .plc-form.long {
    width: 230px;
}

.generate-report .transaction-history-button {
    height: 38px;
    margin-top: 25px;
    border: none;
    background-color: transparent;
    font-size: 15px;
    padding: 0 20px;
    cursor: pointer;
}

.generate-report .transaction-history-button .generate-text {
    font-size: 15px;
    padding: 0 10px;
    color: #336699;
}

.generate-report button:disabled,
.generate-report button[disabled] {
    cursor: default;
}

.generate-report button:disabled .generate-text,
.generate-report button[disabled] .generate-text {
    color: #979797;
}

.generate-report .report-form {
    margin-right: 25px;
    display: inline-block;
}

.generate-report .report-form.resend {
    padding-bottom: 10px;
}

.generate-report .report-form.payment-auth {
    margin-right: 40px;
    margin-bottom: 30px;
}

@media all and (-ms-high-contrast: none) {
    .generate-report .report-form *::-ms-backdrop, .generate-report .report-form.select {
        transform: translateY(-31px);
        z-index: 9999;
    }
}
