.exclusions-main {
    float: left;
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 50px 30px;
    display: flex;
}

.exclusions-select {
    position: relative;
    width: 280px;
    border-radius: 2px;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    padding: 30px;
}

.exclusions-manage {
    width: 668px;
    border-radius: 2px;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    margin-left: 30px;
    padding: 30px;
}

.exclusions-manage div {
    margin-bottom: 15px;
}

.exclusions-manage .exclusion-form {
    margin-right: 25px;
}

.exclusions-manage .exclusion-form input,
.exclusions-manage .exclusion-form label {
    display: block;
}

.exclusions-manage .exclusion-form label {
    margin-bottom: 4px;
}

.exclusions-table {
    margin-top: 50px;
}

.exclusions-table tr,
.exclusions-table table {
    width: 100%;
    display: table;
}

.exclusions-table tbody {
    display: block;
    height: 350px;
    overflow-y: auto;
    /* Trigger vertical scroll    */
}

.exclusions-table td:first-child {
    width: 150px;
}

.exclusions-table td:nth-child(2) {
    width: 150px;
}

.exclusions-table td:nth-child(3) {
    width: 150px;
}

.exclusions-table .row .exclusion-delete-button {
    border: none;
    background-color: transparent;
    margin: 0;
    font-size: 14px;
    text-align: left;
}

.exclusions-table .row .exclusion-delete-button img {
    vertical-align: middle;
    margin: 0 5px;
}

.exclusions-table .row .exclusion-delete-button .remove-text {
    vertical-align: middle;
}

.exclusions-table .row .exclusion-delete-button:hover {
    cursor: pointer;
}
