* {
    font-family: "Open Sans", sans-serif;
    margin: 0;
    padding: 0;
}

body {
    background-color: #f9f9f9;
}

table {
    width: auto;
}

textarea,
input {
    font-weight: 300;
}

.hidden {
    display: none !important;
}

.proview-select {
    font-size: 12px;
    color: #333333;
    width: 100%;
}

.proview-select .select__control {
    border-radius: 2px;
}

.proview-select .select__control:hover {
    border-color: #28AC40;
}

.proview-select .select__control .select__value-container--has-value {
    padding: 4px;
}

.proview-select .select__control.select__control--menu-is-open {
    box-shadow: 0 0 0 1px #28AC40 !important;
    border-color: #28AC40;
}

.proview-select .select__control.select__control--is-focused {
    box-shadow: 0 0 0 1px #28AC40 !important;
    border-color: #28AC40;
}

.proview-select .select__menu .select__menu-list .select__option:hover, .proview-select .select__menu .select__menu-list .select__option--is-focused {
    background-color: #9ede73;
}

.proview-select .select__menu .select__menu-list .select__option--is-selected {
    background-color: #28AC40;
}

.proview-select .select__indicators {
    /* Replace dropdown icon */
}

.proview-select .select__indicators .select__dropdown-indicator {
    display: inline-block;
    position: absolute;
    padding: 19px;
    right: 0;
    top: 0;
    background: url(../icons/caret-down.svg) no-repeat center;
}

.proview-select .select__indicators .select__dropdown-indicator svg {
    display: none;
}

.proview-select .select__indicators .select__indicator-separator {
    display: none;
}

@media screen and (max-width: 600px) {
    .proview-select .select__control {
        width: 100%;
        height: 48px;
    }
}

/* Customize search inputs */
.proview-input {
    height: 38px;
    width: 100%;
    padding: 0 8px;
    font-size: 12px;
    border-radius: 2px;
    border: 1px solid #c9c9c9;
    box-sizing: border-box;
}

.proview-input.long {
    width: 182px;
}

.proview-input::placeholder {
    color: black;
}

.proview-input:hover {
    border: 1px solid #28AC40;
}

.proview-input.error {
    border: solid 1px #cc0000;
}

.proview-input.filter:valid {
    background-color: #28AC40;
    color: white;
}

.proview-input.details {
    width: 100%;
    padding: 8px 0;
    padding-left: 10px;
    height: 99px;
    resize: none;
}

.proview-input.login {
    margin: 12px 0;
}

.proview-button {
    width: 162px;
    height: 50px;
    border: solid 1px #28AC40;
    color: #ffffff;
    background-color: #28AC40;
    border-radius: 2px;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
}

.proview-button.right {
    float: right;
    margin-left: 20px;
}

.proview-button.left {
    float: left;
}

.proview-button:disabled {
    background-color: white;
    color: #28AC40;
    cursor: default;
}

.proview-button.regiser, .proview-button.cancel {
    background-color: white;
    color: #28AC40;
    cursor: pointer;
}

.proview-button.margins {
    margin: 25px 20px;
}

.proview-button.no-margin-left {
    margin-left: 0;
}

.proview-button.long {
    width: 182px;
}

.proview-button.report {
    height: 38px;
}

.proview-button.deny {
    height: 48px;
}

.proview-button.deny .deny-icon {
    padding: 0 12px;
}

.proview-button.deny .dropdown-content {
    left: -20px;
    top: 49px;
    z-index: 1;
}

.proview-button.deny .dropbtn {
    line-height: 50px;
    padding-left: 10px;
    text-decoration: none;
    /* no underline */
    color: #28AC40;
}

.proview-button.deny .dropbtn:focus, .proview-button.deny .dropbtn:active {
    color: #28AC40;
}

.proview-button.reject {
    line-height: 50px;
    padding-left: 10px;
    background-color: #fff;
    color: #28AC40;
    border: solid 0px;
    height: 48px;
}

.proview-button.login {
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
}

.proview-button.register:enabled {
    background-color: white;
    color: #9ede73;
    cursor: pointer;
}

/* Tooltip css */
.img-question {
    margin-left: 3px;
}

.tab {
    margin-left: 20px;
}

#password-tip {
    opacity: 1;
    color: #222;
    background: #f9f9f9;
    box-shadow: 1px 1px 1px 1px #c9c9c9;
}

#password-tip::after {
    border-right-color: #c9c9c9 !important;
}

/* QuickBooks Sync */
.title-qb {
    padding: 0px 8px 20px 8px !important;
    text-align: center !important;
    font-weight: bold !important;
}

.scrollbar-qb {
    width: 650px;
    height: 230px;
    overflow-y: auto;
}

.scrollbar-qb::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.scrollbar-qb::-webkit-scrollbar-thumb {
    background-color: #d5d2d2;
    border-radius: 10px;
}

.font-table {
    font-size: 14px;
}

.modal-qb {
    top: -30px !important;
    width: 650px !important;
    background-color: #fff !important;
    height: 375px !important;
}

.modal-qb #page {
    width: 92%;
}

.qb-info-table {
    width: 100%;
}

.qb-info-table td:first-child {
    width: 15%;
}

.qb-info-table td:nth-child(2) {
    width: 20%;
}

.qb-info-table td:nth-child(3) {
    width: 25%;
}

.qb-info-table td:nth-child(4) {
    width: 30%;
}

/* Right align desired columns */
.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.hoverable {
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    #note button {
        width: 100%;
        max-width: 100%;
        float: none;
    }

    .hide-mobile {
        display: none;
    }

    button {
        height: 48px;
    }

    .proview-input,
    .proview-select {
        font-size: 16px;
    }

    .proview-button {
        width: 40vw;
        max-width: 162px;
    }

    .proview-button.right {
        margin: 0px 0;
    }

    .proview-button.mobile {
        width: 100%;
    }

    .proview-button.margins {
        max-width: 150px;
        margin: 0px 3vw;
    }

    .proview-button.deny .dropdown-content {
        width: 40vw;
        left: 0;
        right: -20px;
    }

    .proview-button.deny .dropdown-content div {
        height: 40px;
        line-height: default;
    }
}

/* To customize the PLC dropdown 
   Mostly dealing with the troubles of
   having little real-estate for
   the dropdown
*/
.plc-select {
    width: 78px;
    font-size: 12px;
    position: absolute;
    top: 0px;
    left: 0px;
    /* Layout of select when not open */
    /* Layout when open */
    /* Dropdown same size as menu */
    /* Layout of PLC boxes */
}

.plc-select .select__placeholder {
    color: black;
}

.plc-select .select__control {
    border-radius: 2px;
    /* If not open, hide select indicators*/
    /* Size container */
    /* If container has values, make sure values
        take up box */
    /* Can't remove when closed */
}

.plc-select .select__control:hover {
    border-color: #28AC40;
}

.plc-select .select__control.select__control--is-focused {
    box-shadow: 0 0 0 1px #28AC40 !important;
    border-color: #28AC40;
}

.plc-select .select__control .select__indicators {
    display: none;
}

.plc-select .select__control .select__value-container {
    height: 36px;
    padding: 0px;
    margin-left: 8px;
}

.plc-select .select__control .select__value-container--has-value {
    margin: 0;
}

.plc-select .select__control .select__multi-value__remove {
    display: none;
}

.plc-select .select__control--menu-is-open {
    width: 370px;
    z-index: 3;
    box-shadow: 0 0 0 1px #28AC40 !important;
    border-color: #28AC40;
    /* Allow box to expand */
    /* Add custom clear indicator on the right */
}

.plc-select .select__control--menu-is-open .select__multi-value__remove {
    display: flex;
    padding: 5px;
}

.plc-select .select__control--menu-is-open .select__multi-value__remove:hover {
    background-color: white;
    color: black;
}

.plc-select .select__control--menu-is-open .select__value-container--has-value {
    height: 100%;
}

.plc-select .select__control--menu-is-open .select__value-container--has-value .select__multi-value {
    border-radius: 0px;
    /* When open, remove '...' */
}

.plc-select .select__control--menu-is-open .select__value-container--has-value .select__multi-value:first-child .select__multi-value__label:after {
    content: "";
}

.plc-select .select__control--menu-is-open .select__indicators {
    display: flex;
}

.plc-select .select__control--menu-is-open .select__indicators .select__clear-indicator {
    background: url(../icons/delete.svg) no-repeat center;
    padding: 15px;
    cursor: pointer;
}

.plc-select .select__control--menu-is-open .select__indicators .select__clear-indicator svg {
    display: none;
}

.plc-select .select__control--menu-is-open .select__indicators .select__indicator-separator {
    display: none;
}

.plc-select .select__control--menu-is-open .select__indicators .select__dropdown-indicator {
    display: none;
}

.plc-select .select__menu {
    width: 370px;
}

.plc-select .select__menu .select__menu-list .select__option:hover, .plc-select .select__menu .select__menu-list .select__option--is-focused {
    background-color: #9ede73;
}

.plc-select .select__multi-value {
    padding: 8px 0;
    margin: 0;
    height: 36px;
    width: 76px;
    border-radius: 1px;
    background-color: #28AC40;
    /* if only one child, hide "...". Else, show it */
}

.plc-select .select__multi-value .select__multi-value__label {
    color: white;
    width: 100%;
    padding: 0;
    text-align: center;
    height: 18px;
    line-height: 18px;
}

.plc-select .select__multi-value:first-child .select__multi-value__label:after {
    content: " ...";
}

.plc-select .select__multi-value:nth-last-child(2) .select__multi-value__label:after {
    content: "";
}

.plc-select.history-form {
    width: 171px;
    top: 0px;
    left: 0px;
    position: relative;
    /* Layout when open */
    /* Dropdown same size as menu */
}

.plc-select.history-form .select__placeholder {
    color: black;
}

.plc-select.history-form .select__control .select__value-container {
    margin-left: 8px;
}

.plc-select.history-form .select__control .select__value-container--has-value .select__single-value {
    padding-right: 10px;
}

.plc-select.history-form .select__control--menu-is-open {
    width: 300px;
}

.plc-select.history-form .select__menu {
    width: 300px;
}

.plc-select.recon-form {
    width: 200px;
    top: 2px;
    left: 0px;
    /* Layout when open */
    /* Dropdown same size as menu */
}

.plc-select.recon-form .select__placeholder {
    color: black;
}

.plc-select.recon-form .select__control .select__value-container {
    margin-left: 8px;
}

.plc-select.recon-form .select__control .select__value-container--has-value .select__single-value {
    padding-right: 10px;
}

.plc-select.recon-form .select__control--menu-is-open {
    width: 300px;
}

.plc-select.recon-form .select__menu {
    width: 300px;
}

.plc-select.manifest {
    left: 10px;
}

.plc-select.exclusions-search {
    width: 270px;
    top: 10px;
    /* Layout when open */
    /* Dropdown same size as menu */
}

.plc-select.exclusions-search .select__placeholder {
    color: black;
}

.plc-select.exclusions-search .select__control .select__value-container {
    margin-left: 8px;
    height: 38px;
}

.plc-select.exclusions-search .select__control .select__value-container--has-value .select__single-value {
    padding-right: 10px;
}

.plc-select.exclusions-search .select__control--menu-is-open {
    width: 270px;
}

.plc-select.exclusions-search .select__menu {
    width: 270px;
    box-shadow: none;
}

.plc-select.exclusions-search .select__menu .select__menu-list {
    max-height: 625px;
}

.plc-select.notes .select__control .select__value-container--has-value {
    margin-left: 8px;
}

.proview-select.resend .select__option {
    background-color: transparent;
    color: black;
}

.proview-select.resend .select__option:hover {
    cursor: pointer;
}
