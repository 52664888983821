.success-notification {
    background: #28AC40;
}

.cancel-notification {
    background: #0069ad;
}

.error-notification {
    background: #cc0000;
}
