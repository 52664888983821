.Modal {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    margin: auto;
    width: 405px;
    height: 230px;
    padding: 25px;
    background-color: #f9f9f9;
}

.Modal.resend {
    width: 400px;
    height: 350px;
}

.Modal.user-info {
    width: 400px;
    height: 400px;
}

.Modal.change-password {
    width: 400px;
    height: 390px !important;
}

.Modal.confirm-qb-resend {
    height: 150px;
    width: 350px;
}

.Modal.cancel-confirm {
    height: 120px;
    width: 350px;
}

.Modal.cancel-confirm h3 {
    margin-bottom: 15px;
}

.Modal.cancel-confirm-approve {
    height: 160px;
    width: 350px;
}

.Modal.cancel-confirm-approve h3 {
    margin-bottom: 15px;
}

.Modal h3 {
    text-align: center;
    padding: 10px 0;
}

.Modal .close-button {
    position: absolute;
    top: 15px;
    right: 20px;
    opacity: 0.6;
    cursor: pointer;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
}

.Modal.deny {
    width: 400px;
    /* Need a specific value to work */
    max-width: 92%;
    height: 230px;
    padding: 3vw;
    background-color: #f9f9f9;
}

.Modal.deny div {
    padding: 15px;
}

.Modal.deny div label {
    padding-left: 10px;
}

.denyreason {
    height: 280px !important;
}

.Modal.plc {
    width: 1150px;
    /* Need a specific value to work */
    height: 700px;
    max-height: 95vh;
    overflow-y: auto;
    top: 0;
    bottom: 0;
    padding: 15px 25px;
}

.Modal.plc hr {
    margin: 10px 7px;
}

.Modal.plc .plc-select {
    top: 0;
    left: -10px;
    width: 350px;
    position: relative;
}

.Modal.plc .plc-select .select__control--menu-is-open {
    width: 350px;
}

.Modal.plc .plc-select .select__menu {
    width: 350px;
    line-height: 20px;
}

.Modal.export {
    width: 400px;
    /* Need a specific value to work */
    height: 230px;
    padding: 25px;
    background-color: #f9f9f9;
}

.Modal.export div {
    padding: 10px;
}

.Modal.export div label {
    padding-left: 10px;
}

.Modal.export div span.note {
    font-size: 12px;
}

.Overlay.deny {
    z-index: 3;
}

.Modal.contact {
    width: 1098px;
    height: 403px;
}

.Modal.contact .plc-select {
    top: 126px;
    left: 260px;
    width: 150px;
}

.Modal.contact .note-table > tr > td:nth-child(2) {
    width: 150px;
}

.Modal.contact .note-table > tr > td:nth-child(3) {
    width: 100px;
}

.Modal.contact .note-table > tr > td:nth-child(4) {
    width: 100px;
}

.margin-top {
    margin-top: 50px;
}
