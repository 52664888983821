.content {
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 24px;
    padding-bottom: 98px;
    min-height: 540px;
    position: relative;
}

table {
    border-collapse: collapse;
}

td {
    padding: 0 6px;
}

#no-results-found {
    font-size: 20px;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 40%;
    bottom: 0;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
}

.no-results-found-exclusions {
    font-size: 20px;
    width: 100%;
    text-align: center;
    margin-top: 20%;
}

.content.transaction-history {
    margin-top: 25px;
    min-height: 0;
    padding-top: 50px;
    padding-bottom: 30px;
}

.content.transaction-history table {
    width: 100%;
}

.content.authorization-table {
    margin-top: 30px;
}

.content.authorization-table table td:first-child {
    width: 100px;
}

.content.authorization-table table td:nth-child(2) {
    width: 150px;
}

.content.authorization-table table td:nth-child(3) {
    width: 300px;
}

.content.authorization-table table td:nth-child(4) {
    width: 125px;
}

.transaction-sum > tr > td:nth-child(1) {
    width: 40px;
}

.transaction-sum > tr > td:nth-child(2) {
    width: 80px;
}

.transaction-sum > tr > td:nth-child(3) {
    width: 80px;
}

.transaction-sum > tr > td:nth-child(5) {
    width: 180px;
}

.transaction-sum > tr > td:nth-child(7) {
    width: 95px;
}

.transaction-sum > tr > td:nth-child(8) {
    width: 85px;
}

.transaction-sum-sync > tr > td:nth-child(1) {
    width: 20px;
}

.transaction-sum-sync > tr > td:nth-child(2) {
    width: 70px;
}

.transaction-sum-sync > tr > td:nth-child(3) {
    width: 40px;
}

.transaction-sum-sync > tr > td:nth-child(5) {
    width: 180px;
}

.transaction-sum-sync > tr > td:nth-child(7) {
    width: 70px;
}

.transaction-sum-sync > tr > td:nth-child(8) {
    width: 70px;
}

.transaction-sum-sync > tr > td:nth-child(9) {
    width: 70px;
}

.transaction-man > tr > td:nth-child(1) {
    width: 10%;
}

.transaction-man > tr > td:nth-child(2) {
    width: 85px;
}

.transaction-man > tr > td:nth-child(3) {
    width: 10%;
}

.transaction-man > tr > td:nth-child(4) {
    width: 10%;
}

.transaction-man > tr > td:nth-child(5) {
    width: 17%;
}

.transaction-man > tr > td:nth-child(6) {
    width: 10%;
}

.transaction-man > tr > td:nth-child(7) {
    width: 33%;
}

.transaction-man > tr > td #billingName {
    width: 200px;
}

.plc-migration > tr > td:nth-child(1) {
    width: 150px;
}

.plc-migration > tr > td:nth-child(2) {
    width: 500px;
}

.plc-migration > tr > td:nth-child(3) {
    width: 200px;
}

.plc-migration > tr > td:nth-child(4) {
    width: 200px;
}

.recurring-pay > tr > td:nth-child(1) {
    width: 60px;
}

.recurring-pay > tr > td:nth-child(2) {
    width: 60px;
}

.recurring-pay > tr > td:nth-child(3) {
    width: 70px;
}

.recurring-pay > tr > td:nth-child(4) {
    width: 165px;
}

.recurring-pay > tr > td:nth-child(5) {
    width: 90px;
}

.recurring-pay > tr > td:nth-child(6) {
    width: 70px;
}

.recurring-pay > tr > td:nth-child(7) {
    width: 75px;
}

.recurring-pay > tr > td:nth-child(10) {
    width: 120px;
}

.recurring-pay .row-button {
    padding: 5px;
}

.recurring-payment table,
.plc-migration table,
.transaction-manifest table {
    width: 100%;
}

.transaction-man .row .transaction-id-button {
    padding: 0;
}

.transaction-man .row td {
    vertical-align: top;
    padding: 10px;
}

@media only screen and (max-width: 600px) {
    .content {
        padding: 0;
        padding-bottom: 0;
        min-height: 0;
        border-radius: 0;
        box-shadow: 0 0 0 0;
    }

    /* Force table to not be like tables anymore */
    table,
    tbody,
    td.row,
    tr.row:not(.hide-mobile) {
        display: block;
    }

    tr.row::after {
        display: block;
        content: url(../icons/caret-right.svg);
        /* background-size: 28px 28px; */
        position: absolute;
        right: 20px;
        -webkit-transform: translateY(-100%);
        transform: translateY(-125%);
        float: right;
    }

    .transaction-id-button {
        height: 24px;
    }
}

.text-note {
    font-size: 12px;
    margin-top: 15px;
}
